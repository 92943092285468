import React, { useState, useCallback, useEffect } from "react"
import * as Styles from "./index.module.scss"
import backgroundImage01 from "../../images/korea/plan/background01.jpg"
import backgroundImage01Sp from "../../images/korea/plan/background01_sp.jpg"
import backgroundImage02 from "../../images/korea/plan/background02.jpg"
import backgroundImage02Sp from "../../images/korea/plan/background02_sp.jpg"

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    width: number | undefined
    height: number | undefined
  }>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowSize
}

const TabsComponent = () => {
  //タブの状態をとる
  const [state, setState] = useState({
    tab: "panel1",
  })
  
  const windowSize = useWindowSize()

  //backgroundImageUrlsの型を宣言
  type BackgroundImageUrlsType = {
    [key: string]: string
  }

  // 画面幅に基づいて適切な背景画像を選択
  const backgroundImageUrls: BackgroundImageUrlsType = {
    panel1:
      windowSize.width && windowSize.width <= 767
        ? backgroundImage01Sp
        : backgroundImage01,
    panel2:
      windowSize.width && windowSize.width <= 767
        ? backgroundImage02Sp
        : backgroundImage02,
  }

  // クリックしたときのイベントハンドラー
  const handleTabClick = useCallback(event => {
    // イベント発生源の要素を取得
    const element = event.currentTarget

    // aria-controls 属性の値を取得（タブの名前） ex)"panel1"
    const tabState = element.getAttribute("aria-controls")

    // プロパティーを更新
    setState({
      tab: tabState,
    })
  }, [])

  const backgroundImageUrl = backgroundImageUrls[state.tab]

  return (
    <div
      className={Styles.topPlanInner}
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
      }}
    >
      <div className={Styles.topPlanContent}>
        <h2 className={`${Styles.topPlanHeading} ${Styles.topHeadingLv2}`}>
          <span className={Styles.ko}>일정안내</span>
          <span className={Styles.en}>Plan</span>
        </h2>
        <div className={Styles.topPlanTabListWrapper}>
          <ul role="tablist" className={`${Styles.topPlanTabList} tab-nav`}>
            <li role="presentation" className={Styles.topPlanTabItem}>
              <button
                role="tab"
                aria-controls="panel1"
                aria-selected={state.tab === "panel1"}
                onClick={handleTabClick}
                type="button"
                className={Styles.topPlanTabButton}
              >
                <span>
                  일본 소비자 대상 국경을 넘는 온라인 쇼핑몰 지원 계획
                </span>
              </button>
            </li>
            <li role="presentation" className={Styles.topPlanTabItem}>
              <button
                role="tab"
                aria-controls="panel2"
                aria-selected={state.tab === "panel2"}
                onClick={handleTabClick}
                type="button"
                className={Styles.topPlanTabButton}
              >
                <span>일본 시장을 대상으로 한 마케팅 지원 계획</span>
              </button>
            </li>
          </ul>
        </div>
        <div className={Styles.topPlanListWrapper}>
          <div
            className={Styles.topPlanItem}
            role="tabpanel"
            id="panel1"
            aria-hidden={state.tab !== "panel1"}
          >
            <p className={Styles.topPlanItemText}>
              커머스 전략, 사이트 구축, 프로모션을 종합적으로 지원합니다!
            </p>
            <div className={Styles.topPlanSubListWrapper}>
              <ol className={Styles.topPlanSubList}>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>시장조사</p>
                  </div>
                  <p className={Styles.topPlanSubItemText}>
                    귀사의 제품에 맞는 타깃 선정,일본 소비자의 동향과 관심사를
                    조사합니다.
                  </p>
                </li>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>
                      온라인 쇼핑몰·콘텐츠 제작
                    </p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    일본에서 E-커머스의 UI/UX 개선을 이어온 지식과 경험으로,
                    일본의 고품질 제작 수준으로 사이트와 콘텐츠를 제작합니다.
                  </p>
                </li>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>
                      프로모션 - SNS·광고
                    </p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    타깃에 맞는 일본의 플랫폼에서 SNS와 광고의 플래닝·운영을
                    진행합니다.
                  </p>
                </li>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>
                      효과 분석·정책 수립
                    </p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    실행 후의 리포트와 넥스트 스텝의 구상까지 담당합니다.
                  </p>
                </li>
              </ol>
            </div>
          </div>
          <div
            className={Styles.topPlanItem}
            role="tabpanel"
            id="panel2"
            aria-hidden={state.tab !== "panel2"}
          >
            <p className={Styles.topPlanItemText}>
              프로모션 전략, 콘텐츠 제작, SNS 운영을 계획하고 실행합니다!
            </p>
            <div className={Styles.topPlanSubListWrapper}>
              <ol className={Styles.topPlanSubList}>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>시장 리서치</p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    현지 시장의 소비자 트렌드, 관심사, 경쟁사 등을 조사합니다.
                  </p>
                </li>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>콘텐츠 제작</p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    일본 마케팅과 디지털 경험이 풍부한 멤버가 일본의 고품질 제작
                    수준으로 효과적인 콘텐츠를 만듭니다.
                  </p>
                </li>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>
                      프로모션 - SNS·광고
                    </p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    타겟에 맞는 일본의 플랫폼에서 SNS 및 광고 플래닝 및 운영을
                    합니다.
                  </p>
                </li>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>
                      효과 분석·정책 수립
                    </p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    효과 분석 · 정책 수립 실행 후의 보고서 및 다음 단계를
                    고안하기까지 담당합니다.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabsComponent
